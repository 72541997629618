import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import cogoToast from 'cogo-toast';
import { useDropzone } from 'react-dropzone';

import BackButton from '@components/BackButton';
import {
    fetchGym,
    deleteGym,
    fetchTabletent,
    deleteImage,
    deleteGymDocs,
    uploadGymDocs,
    updateGym,
} from '@redux/modules/gyms/actions';
import { getGym } from '@redux/modules/gyms/selectors';
import { AsyncDispatch } from '@redux/types';
import { getLegalEntitiesList } from '@redux/modules/legal-entity/selectors';
import { fetchLegalEntitiesList } from '@redux/modules/legal-entity/actions';
import { fetchManagersList } from '@redux/modules/managers/actions';
import { getManagersList } from '@redux/modules/managers/selectors';
import { ButtonNew } from '@components/ButtonNew';
import { getTags } from '@redux/modules/tags/selectors';
import { Tag } from '@t/tag';
import { fetchTags } from '@redux/modules/tags/actions';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import { TableTentType } from '@t/gym';
import { TableTentModalForm } from './TableTentModalForm';
import Form from './Form';
import styles from './styles.module.css';

export const getKeyFromUrl = (url: string): string => {
    const list = url.split('/');
    return list[list.length - 1];
};

const getMappedTagOptions = (tags: Tag[], tagTypeValue: string) => {
    return tags.reduce<{ label: string; value: string }[]>((acc, tag) => {
        if (tag.tagType.find((type) => type.tagTypeValue === tagTypeValue)) {
            acc.push({
                label: tag.tagName,
                value: tag._id,
            });
        }
        return acc;
    }, []);
};

const TABLE_TENT_OPTIONS_LIST = [
    {
        title: 'Поминутная тренировка 2 (белая плашка)',
        value: TableTentType.TENT_PER_MIN_2,
    },
    {
        title: 'Спирит (белая плашка)',
        value: TableTentType.TENT_SPIRIT,
    },
    {
        title: 'Занятия новый (белая плашка)',
        value: TableTentType.TENT_WORKOUTS_1,
    },
    {
        title: 'Массаж новый (белая плашка)',
        value: TableTentType.TENT_MASSAGE_1,
    },
    {
        title: 'Поминутная тренировка 1 (фиолетовая плашка)',
        value: TableTentType.TENT_PER_MIN_1,
    },
    {
        title: 'Общий старый',
        value: TableTentType.TENT_DEFAULT_OLD,
    },
];

export const GymDetailsNew: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const gym = useSelector(getGym(id || ''));
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const legalEntitiesList = useSelector(getLegalEntitiesList);
    const managersList = useSelector(getManagersList);
    const tags = useSelector(getTags);
    const [changedGymForm, setChangedGymForm] = useState<boolean>(false);
    const [modalFileActive, setModalFileActive] = useState<boolean>(false);
    const [loading, setloading] = useState<boolean>(false);
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const [downloadQRModalActive, setDownloadQRModalActive] = useState(false);

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (!acceptedFiles.length) {
                cogoToast.error(`Можно загрузить документы с расширением pdf`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                return;
            } else {
                const { hide } = cogoToast.loading('Загружаем документы...', {
                    position: 'top-right',
                    hideAfter: 0,
                });
                const MAX_LENGTH = 3;
                const MAX_SIZE = 150000000;
                const numOfFiles = Array.from(acceptedFiles).length;

                if (gym.docs.length + numOfFiles > MAX_LENGTH) {
                    cogoToast.error(
                        `Нельзя загрузить более ${MAX_LENGTH} документов`,
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                    return;
                }
                for (let i = 0; i < numOfFiles; ++i) {
                    const validExtensions = ['pdf'];
                    const fileExtension = acceptedFiles[i].type.split('/')[1];
                    const fileSize = acceptedFiles[i].size;

                    if (!validExtensions.includes(fileExtension)) {
                        cogoToast.error(
                            `Можно загрузить документы с расширением pdf`,
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        return;
                    }

                    if (fileSize > MAX_SIZE) {
                        cogoToast.error(
                            `Нельзя загрузить документ размера более 15MB, документ "${acceptedFiles[i].name}" слишком большой`,
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        return;
                    }
                }
                setModalFileActive(false);
                dispatch(
                    uploadGymDocs({
                        files: acceptedFiles,
                        gymId: id,
                    })
                )
                    .then(() => {
                        cogoToast.success('Загрузка документов завершена', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    })
                    .catch(() => {
                        cogoToast.error('Ошибка при загрузке документов', {
                            position: 'top-right',
                            hideAfter: 4,
                        });
                    })
                    .finally(() => {
                        hide && hide();
                    });
            }
        },
        [gym]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': [],
        },
    });
    useEffect(() => {
        if (!id) {
            return;
        }
        if (isSuperAdmin) {
            dispatch(fetchLegalEntitiesList());
            dispatch(fetchManagersList());
        }
        dispatch(fetchGym(id));
        dispatch(fetchTags());
    }, [dispatch, id]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }

            const { hide } = cogoToast.loading('Сохраняем изменения...', {
                position: 'top-right',
                hideAfter: 0,
            });
            setloading(true);
            dispatch(updateGym({ id, values }))
                .then((gym) => {
                    setChangedGymForm(false);
                    if (!gym) {
                        cogoToast.error(
                            'Ошибка при обновлении деталей фитнес-площадки',
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    } else {
                        cogoToast.success('Изменения сохранены', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }
                })
                .catch(() => {
                    cogoToast.error(
                        'Ошибка при обновлении деталей фитнес-площадки',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                })
                .finally(() => {
                    hide && hide();
                    setloading(false);
                });
        },
        [id, dispatch, changedGymForm]
    );
    const onDeleteImageClick = useCallback(
        (event) => {
            if (!id) {
                return;
            }
            const { hide } = cogoToast.loading('Удаляем фото...', {
                position: 'top-right',
                hideAfter: 0,
            });
            const s3Key: string = getKeyFromUrl(event.target.id);
            dispatch(deleteImage({ gymId: id, key: s3Key }))
                .then(() => {
                    cogoToast.success('Удаление фото завершено', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .catch(() => {
                    cogoToast.error(`Ошибка при удалении фото`, {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => {
                    hide && hide();
                });
        },
        [id, dispatch]
    );

    const onDeleteDocsClick = useCallback(
        (event) => {
            if (!id) {
                return;
            }
            const { hide } = cogoToast.loading('Удаляем документы...', {
                position: 'top-right',
                hideAfter: 0,
            });
            const s3Key: string = getKeyFromUrl(event.target.id);
            dispatch(deleteGymDocs({ gymId: id, key: s3Key }))
                .then(() => {
                    cogoToast.success('Удаление документов завершено', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .catch(() => {
                    cogoToast.error(`Ошибка при удалении документов`, {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => {
                    hide && hide();
                });
        },
        [id, dispatch]
    );

    const onDelete = useCallback(() => {
        dispatch(deleteGym(id))
            .then(() => {
                history.push(`/dashboard/gyms`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении фитнес-площадки', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const onDownloadQR = useCallback(
        (values: { tableTentVariantId: string }) => {
            const { hide } = cogoToast.loading('Формируем тейбл-тент...', {
                position: 'top-right',
                hideAfter: 0,
            });
            setDownloadQRModalActive(false);
            dispatch(
                fetchTabletent({
                    params: {
                        id,
                    },
                    query: {
                        type: values.tableTentVariantId as TableTentType,
                    },
                })
            )
                .then((tabletent) => {
                    const link = document.createElement('a');
                    link.download = 'gogym-tabletent.jpeg';
                    link.href = tabletent.base64;
                    link.click();
                    cogoToast.success('Тейбл-тент готов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .catch(() => {
                    cogoToast.error(
                        'Не удалось скачать QR код. Повторите попытку позже',
                        {
                            position: 'top-right',
                            hideAfter: 5,
                        }
                    );
                })
                .finally(() => {
                    hide && hide();
                });
        },
        [dispatch, id]
    );

    const legalEntitysOptions = legalEntitiesList.map((legalEntity) => ({
        label: legalEntity.name,
        value: legalEntity._id,
    }));
    const legalEntityOptionId =
        legalEntitysOptions.find(
            (legalEntitysOption) =>
                legalEntitysOption.value === gym?.legalEntityId
        )?.value || null;

    const AllManagersOptions = managersList.map((manager) => ({
        label: `${manager.firstName}  -  ${manager.email}`,
        value: manager._id,
    }));
    const managersOptionsIds = AllManagersOptions.reduce<string[]>(
        (acc, managersOption) => {
            if (gym?.managersId.includes(managersOption.value)) {
                acc.push(managersOption.value);
            }
            return acc;
        },
        []
    );

    const gymServicesOptions = getMappedTagOptions(tags, 'GYMSERVICE');
    const gymServicesOptionsIds = gymServicesOptions.reduce<string[]>(
        (acc, gymService) => {
            if (gym?.services.includes(gymService.value)) {
                acc.push(gymService.value);
            }
            return acc;
        },
        []
    );

    const gymAdvantagesOptions = getMappedTagOptions(tags, 'GYMADVANTAGE');
    const gymAdvantagesOptionsIds = gymServicesOptions.reduce<string[]>(
        (acc, gymAdvantage) => {
            if (gym?.advantages.includes(gymAdvantage.value)) {
                acc.push(gymAdvantage.value);
            }
            return acc;
        },
        []
    );

    const initialValues = useMemo(() => {
        if (!gym || !gym?._id) {
            return {};
        }

        const values = {
            ...gym,
            legalEntityId: legalEntityOptionId,
            managersId: managersOptionsIds,
            services: gymServicesOptionsIds,
            advantages: gymAdvantagesOptionsIds,
            geo: {
                address: gym.address,
                shortAddress: gym.shortAddress,
                position:
                    gym.location?.coordinates?.length > 1
                        ? {
                              lat: gym.location?.coordinates[1],
                              lng: gym.location?.coordinates[0],
                          }
                        : {
                              lat: 0,
                              lng: 0,
                          },
            },
            fixedPrice: gym.pricePerMinRub,
            website: gym.website,
            phone: gym.phone,
            city: { title: gym?.city, id: gym?.cityId },
            prepayment: gym.prepayment ?? 100,
        };

        return values;
    }, [
        gym,
        legalEntityOptionId,
        managersOptionsIds,
        gymServicesOptionsIds,
        gymAdvantagesOptionsIds,
    ]);

    const tableTentOptions = useMemo(() => {
        if (!gym || !gym?._id) {
            return [];
        }

        if (isSuperAdmin) {
            return TABLE_TENT_OPTIONS_LIST;
        }

        let availableOptions: TableTentType[] = [];

        if (gym.spirit) {
            availableOptions = [TableTentType.TENT_SPIRIT];
            return TABLE_TENT_OPTIONS_LIST.filter((tentOption) =>
                availableOptions.includes(tentOption.value)
            );
        }

        if (gym.hasGroupWorkouts && !gym.hasPerMinWorkouts) {
            availableOptions = [
                TableTentType.TENT_WORKOUTS_1,
                TableTentType.TENT_MASSAGE_1,
            ];
        }

        if (!gym.hasGroupWorkouts && gym.hasPerMinWorkouts) {
            availableOptions = [TableTentType.TENT_PER_MIN_2];
        }

        if (gym.hasGroupWorkouts && gym.hasPerMinWorkouts) {
            availableOptions = [
                TableTentType.TENT_WORKOUTS_1,
                TableTentType.TENT_PER_MIN_2,
            ];
        }

        return TABLE_TENT_OPTIONS_LIST.filter((tentOption) =>
            availableOptions.includes(tentOption.value)
        );
    }, [gym]);

    const tableTentModalInitialValues = useMemo(() => {
        if (!gym || !gym?._id) {
            return {
                tableTentVariantId: '',
            };
        }

        return {
            tableTentVariantId: tableTentOptions[0].value,
        };
    }, [gym, tableTentOptions]);

    if (!gym || !gym?._id) {
        return <div className={styles.container}>Загрузка...</div>;
    }

    return (
        <div className={styles.container}>
            <BackButton
                to="/dashboard/gyms"
                title="К списку площадок"
                className={styles.backBtn}
            />

            {isSuperAdmin && (
                <ButtonNew
                    appearance="qr"
                    onClick={() => history.push(`/dashboard/old-gyms/${id}`)}
                    className={styles.oldDesign}
                >
                    Старый дизайн
                </ButtonNew>
            )}

            <div className={styles.main}>
                <div>
                    <div className={styles.title}>{gym.title}</div>
                    <div className={styles.qrDesc}>
                        Вы можете скачать и распечатать табличку с QR-кодом
                        <br /> для посетителей площадки:
                    </div>

                    <ButtonNew
                        appearance="qr"
                        icon="download"
                        onClick={() => setDownloadQRModalActive(true)}
                    >
                        Скачать QR код
                    </ButtonNew>
                </div>

                <QRCode value={`gogym://gyms/${gym._id}`} />
            </div>

            {!gym.authorized && (
                <div className={styles.notAuthorizedBadge}>
                    Фитнес площадка ожидает подтверждения от команды GOGYM. В
                    настоящее время фитнес-площадка не отображается у
                    пользователей приложения. Пожалуйста, ожидайте.
                </div>
            )}

            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                gymId={gym._id}
                legalEntitysOptions={legalEntitysOptions}
                AllManagersOptions={AllManagersOptions}
                gymServicesOptions={gymServicesOptions}
                gymAdvantagesOptions={gymAdvantagesOptions}
                onDeleteImageClick={onDeleteImageClick}
                onDropFiles={onDrop}
                getRootPropsFiles={getRootProps}
                getInputPropsFiles={getInputProps}
                isDragActiveFiles={isDragActive}
                modalFileActive={modalFileActive}
                setModalFileActive={setModalFileActive}
                onDeleteDocsClick={onDeleteDocsClick}
                onDelete={onDelete}
                loading={loading}
            />
            {downloadQRModalActive && (
                <TableTentModalForm
                    active={downloadQRModalActive}
                    setActive={(prev) => setDownloadQRModalActive(prev)}
                    options={tableTentOptions}
                    onDownloadQR={onDownloadQR}
                    initialValues={tableTentModalInitialValues}
                />
            )}
        </div>
    );
};

export default GymDetailsNew;
